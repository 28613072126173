.backgroundBox {
  top: 60%;
  left: 50%;
  height: 10%;
  width: 60%;
  position: absolute;

  transform: translate(-50%);

  background-color: rgba(207, 255, 235, 0.05);
  border: none;
  border-left: 5px inset rgba(166, 255, 189, 0.3);
  text-align: left;
  border-radius: 3px;
  display: inline;

  cursor: text;
  padding-left: 10px;
}

/* Testing */
.visibleText {
  font-family: liquidCrystal;
  color: rgb(166, 255, 189);
  font-weight: normal;
  font-size: 0.75em;
}

.cursorUnderline {
  font-size: 0.2em;
  background-color: transparent;
  color: transparent;
  position: relative;
  opacity: 0.5;
  animation: blink 0.75s infinite;
  margin: 2px;
}

.cursorBlock {
  font-size: 0.75em;
  position: relative;
  opacity: 0.5;
  animation: blink 0.75s infinite;
  margin: 2px;
}

/* Animate the cursor to blink */
@keyframes blink {
  0% {
    background-color: transparent;
    color: transparent;
  }
  70% {
    color: rgb(117 195 142);
    background-color: rgb(117 195 142);
  }
  100% {
    background-color: transparent;
    color: transparent;
  }
}

/* Hide the actual text input area */
.hiddenInputBox {
  left: -1000px;
  position: absolute;
}
