.backgroundSpecial {
  background-image: url("../images/background/servicesBackground.png");
  background-size: contain;
}

.panelTravel {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../images/mapbase.jpg");
  background-size: contain;
}

/* region planetButtons */
#caliban {
  left: 75.5%;
  top: 53.2%;
}

#earth {
  left: 65.6%;
  top: 14.5%;
}

#pertia {
  left: 26.8%;
  top: 12.8%;
}

#setebos {
  left: 11.8%;
  top: 40.5%;
}

#taspra {
  left: 86.5%;
  top: 27%;
}

#umbriel {
  left: 50%;
  top: 71.2%;
}

/* endregion */
