@import "common";
@import "Services.module.scss";

.withdrawCol {
  @include contentColumn("left");
}

.depositCol {
  @include contentColumn("right");
}

.buttonWithdraw {
  @include footer-button;
  @include button-images("withdraw");
}

.buttonDeposit {
  @include footer-button;
  @include button-images("deposit");
}

.bankInput {
  width: 100px;
}
