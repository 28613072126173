@import "common";

.container {
  min-height: 100vh;
  background-color: transparent;
}

.logo {
  /* Set the logo as background image */
  background-image: url("../images/background/shortLogo.jpg");
  background-repeat: no-repeat;
  background-size: 100% 100%;

  /* The background Sky Smuggler logo */
  position: absolute;

  height: 585px;
  width: 964px;

  max-width: 120%;
  max-height: 70vmin;

  /* Center horizontally */
  left: 50%;
  transform: translate(-50%);

  /* Move up to regain some vertical space */
  top: -20px;
}

.console {
  /* Console that holds other components */

  background-image: url("../images/mainbox.jpg");
  background-repeat: no-repeat;
  background-color: rgba(0, 0, 0, 0.1);
  background-blend-mode: darken;

  position: absolute;

  /* Desired dimensions */
  height: 763px;
  width: 748px;

  /* Scale to smaller widths if needed */
  max-width: 100vmin;
  max-height: 100vmin;

  top: 18vmin;
  left: 50%;
  transform: translate(-50%);
}

.consoleBackground {
  width: 100%;
  height: 100%;
  object-fit: fill;
}

/* region feed */

.feed {
  position: absolute;

  height: 98%;
  width: 56%;

  left: 0;
  top: 0;

  margin: 1%;
}

.feedBackground {
  background-image: url("../images/feedglass.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;

  left: 0;
  top: 0;
  width: 100%;
  height: 90%;
}

.feedText {
  /* Positioning in console */
  position: absolute;
  top: 20%;
  left: 11%;
  width: 77%;
  height: 69%;

  /* Text style */
  font-family: terminusmedium, monospace;
  font-size: calc(6px + 1vmin);
  color: #cfffeb;
  overflow: hidden;
}

.feedItems {
  /* Text style */
  font-family: terminusmedium, monospace;
  font-size: calc(6px + 1vmin);
  color: #cfffeb;
  overflow: hidden;
}

.feedBottomFlash {
  background-image: url("../images/decoration/blankPanelSmall.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;

  position: absolute;

  left: 0;
  top: 92%;

  width: 100%;
  height: 8%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  & button {
    @include reset-button;
    justify-self: start;
    padding: 5px;
  }
}

/* endregion */

/* region playButton */

.playBtnBackground {
  position: absolute;
  background-image: url("../images/playbox.png");

  width: 41%;
  height: 8%;

  left: 57%;
  top: 0;
  margin: 1%;
}

.playButton {
  position: absolute;
  left: 1%;
  top: 0;
  width: 98%;
  height: 100%;

  background-image: url("../images/buttons/playNormal.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.playButton:hover {
  background-image: url("../images/buttons/playHover.png");
}

.playButton:active {
  background-image: url("../images/buttons/playClick.png");
}

/* endregion */

/* region scoreContainer */

.scoreContainer {
  background-image: url("../images/background/scoreBackground.png");
  background-repeat: no-repeat;
  background-size: 100% 80%;
  position: absolute;
  width: 41%;
  height: 89%;
  left: 57%;
  top: 9%;
  margin: 1%;

  z-index: 1;
}

.scoreBackground {
  background-image: url("../images/background/scoreConsole.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;

  position: absolute;
  top: 7%;
  left: 0;
  height: 92%;
  width: 101%;
  margin: 1% -0.5% 0;
}

.scoreList {
  position: absolute;
  margin: 1% 15%;
  height: 31%;
  width: 66%;

  padding: 0 2%;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.scoreList.allTime {
  top: 15%;
}

.scoreList.weekly {
  top: 57%;
}

.scoreItem {
  /* Scale font down as screen gets smaller */
  font-size: calc(4px + 1.3vmin);
  line-height: calc(4px + 1.3vmin);
}
.scoreName {
  float: left;
}
.scoreScore {
  float: right;
}

.instructionButton {
  background-image: url("../images/buttons/instructionNormal.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;

  position: absolute;
  width: 98%;
  height: 7%;
  top: 0;
  left: 0;

  margin: 1%;
}

.instructionButton:hover {
  background-image: url("../images/buttons/instructionHover.png");
}

.instructionButton:active {
  background-image: url("../images/buttons/instructionClick.png");
}

/* endregion */
