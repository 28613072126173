.panelTrade {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../images/background/tradeBackground.png");
  background-size: contain;
}

.market {
  position: absolute;

  left: 5%;
  top: 27%;

  width: 68.5%;
  height: 46%;

  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.marketHeader {
  position: absolute;

  left: 5%;
  top: 20%;

  width: 68.7%;
  height: 25px;

  padding-bottom: -2%;
}
.marketHeader * {
  float: left;
  font-size: calc(1.1vmin + 10px);
  line-height: 25px;
  padding-left: 1.9%;
  color: rgb(207, 255, 235);
}

.cargoRow {
  float: left;
  height: 30px;
  width: 100%;
}

.cargoRow * {
  float: left;
  font-size: 18px;
  line-height: 30px;
  padding-left: 1.9%;
  color: rgb(207, 255, 235);
}

.selectedCargo {
  font-weight: bold;
}

.cargoIcon {
  object-fit: contain;
  height: 100%;
  margin-left: 3.8%;
  width: 4.5%;
}

.cargoName {
  width: 23%;
  text-align: left;
}

.cargoPrice {
  text-align: left;
  width: 15%;
}

.cargoOnBoard {
  text-align: left;
  width: 13%;
}

input.cargoQuantity {
  height: 16px;
  width: 9%;
  border: 1px solid rgb(207, 255, 235);
  background-color: transparent;
  margin-top: 5px;
  margin-left: 2.8%;
  text-align: right;
  /*Keep text centered for Safari*/
  line-height: 18px;

  font-family: liquidCrystal;
}

input.cargoQuantity.selectedCargo {
  font-weight: normal;
  outline: 1px solid rgb(207, 255, 235);
}

/* Remove webkit flashy stuff */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.maxHeader {
  width: 5.6%;
  margin-right: 5%;
  float: right;
}

.maxButton {
  width: 5.6%;
  height: 30px;
  cursor: pointer;
  background-size: contain;
  background-repeat: no-repeat;
  margin: 0;
  padding: 0;
}

.maxSell {
  background-image: url("../images/buttons/maxSellNormal.png");
  margin-left: 1%;
}

.maxSell:hover {
  background-image: url("../images/buttons/maxSellHover.png");
}

.maxSell:active {
  background-image: url("../images/buttons/maxSellClick.png");
}

.maxBuy {
  background-image: url("../images/buttons/maxBuyNormal.png");
  margin-left: 5.5%;
}

.maxBuy:hover {
  background-image: url("../images/buttons/maxBuyHover.png");
}

.maxBuy:active {
  background-image: url("../images/buttons/maxBuyClick.png");
}

.transactionButtons {
  position: absolute;

  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;

  top: 80%;
  left: 8.5%;

  height: 9.3%;
  width: 62.3%;
}

.transactionButtons * {
  height: 55%;
  width: 45%;
  cursor: pointer;
  background-size: 100% 100%;
}

.buyButton {
  background-image: url("../images/buttons/buyNormal.png");
}

.buyButton:hover {
  background-image: url("../images/buttons/buyHover.png");
}

.buyButton:active {
  background-image: url("../images/buttons/buyClick.png");
}

.sellButton {
  background-image: url("../images/buttons/sellNormal.png");
}

.sellButton:hover {
  background-image: url("../images/buttons/sellHover.png");
}

.sellButton:active {
  background-image: url("../images/buttons/sellClick.png");
}
