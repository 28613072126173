.game-background {
  text-align: center;
  background-color: black;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.console-background {
  background-size: cover;
  min-width: 100%;
  min-height: 100%;
}

.overlay-light-text {
  display: inline-block;
  vertical-align: middle;
  line-height: 700px;
  height: 200px;
  font-size: 4em;
}

.overlay-light {
  background-color: rgba(0, 30, 30, 0.3);
  height: 100%;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 5;
  position: absolute;
  vertical-align: middle;
  display: inline-block;
  line-height: normal;
}

.test-box {
  border: 1px dashed red;
}

.component {
  position: absolute;
  top: 0;
  left: 0;
  width: 20%;
}

.is-size-3 {
  font-size: 2rem;
}

.is-size-4 {
  font-size: 1.5rem;
}

.is-size-5 {
  font-size: 1.25rem;
}

.is-size-6 {
  font-size: 1rem;
}

.left {
  position: absolute;
  right: 50%;
  padding: 5px;
  top: 50%;
  transform: translate(0, -50%);
  border: solid 1px white;
}
.right {
  position: absolute;
  left: 50%;
  padding: 5px 10px;
  top: 50%;
  transform: translate(0, -50%);
}

.centered {
  position: relative;
  text-align: center;
  top: 50%;
  transform: translate(0, -50%);
}

.bare-container {
  display: contents;
}
