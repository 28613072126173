@import "common";

.backButton {
  @include standard-button;
  @include button-images("back");
}

@mixin services-button {
  @include standard-button;
  bottom: 16.2%;
}

.planetButton {
  // planetButton is transparent unless hovered/clicked. Background image of the
  // map shows the un-hovered image already.
  @include reset-button;

  position: absolute;
  height: 10.4%;
  width: 6.8%;

  border-radius: 50%;
  background-size: contain;

  &:hover {
    background-image: url("../images/buttons/planetHover.png");
  }
  &:active {
    background-image: url("../images/buttons/planetClick.png");
  }
  &:disabled {
    background-image: none;
    cursor: not-allowed;
  }
}

.travelButton {
  @include standard-button;
  @include button-images("travel");
}

.shipyardButton {
  @include services-button;
  @include button-images("shipyard");
}

.loansharkButton {
  @include services-button;
  @include button-images("loanshark");
}

.bankButton {
  @include services-button;
  @include button-images("bank");
}

.fueldepotButton {
  @include services-button;
  @include button-images("fuelDepot");
}
