.status-element-box {
  display: flex;
  justify-content: center;
  align-items: baseline;
}

.status-element-box.center-left {
  justify-content: flex-end;
}

.status-element-box.center-right {
  justify-content: flex-start;
}

.status-element {
  padding: 5px 10px;
  position: relative;
  top: 50%;
  transform: translate(0, -50%);
}
