.planetImage {
  position: absolute;

  top: 11.5%;
  left: 78.3%;

  height: 25%;
  width: 16.1%;

  object-fit: contain;
}

.planetInfo {
  position: absolute;

  top: 38%;
  left: 76.7%;

  height: 30%;
  width: 19.3%;

  font-size: 18px;
  line-height: 23px;
  text-align: center;
}
