.positionPlanet {
  position: absolute;
  top: 4%;
  left: 3.1%;
  width: 12%;
  height: 4%;
}

.positionTurns {
  position: absolute;
  top: 4%;
  right: 2.6%;
  width: 12%;
  height: 4%;
}

.positionCargoBays {
  position: absolute;
  top: 4%;
  left: 50%;
  width: 32%;
  height: 4%;
  padding-left: 10px;
}

.positionCredits {
  position: absolute;
  top: 4%;
  right: 50%;
  width: 32%;
  height: 4%;
  padding-right: 10px;
}
