.serviceSplitPanel {
  // Background for the services panels
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: {
    image: url("../images/background/servicesBackground.png");
    size: contain;
  }
}

.serviceSplitContent {
  // Content box (split screen) on services panels.
  // Includes header, two columns, and the footer buttons

  // Position inside the background panel image
  position: absolute;
  top: 11.2%;
  left: 5%;
  width: 68.6%;
  height: 78.2%;

  // Set up as a grid display to get a header and two columns
  display: grid;
  grid-template: {
    columns: 1fr 1fr;
    rows: 20% 60% 20%;
  }
  font: {
    family: "Liquid Crystal", monospace;
    size: 20px;
  }
}

.serviceHeader {
  // Header box (i.e., Shipyard or Loan Shark text)
  grid: {
    column: 1 / 3; // Span both columns (line 1 to line 3)
    row: 1;
  }

  justify-self: center; // Center horizontally
  align-self: center; // Align to center-ish of cell
  margin-bottom: 2%;
}

.serviceFooter {
  grid: {
    column: 1 / 3;
    row: 3;
  }

  margin: {
    top: 6%;
    left: 5.2%;
    right: 4.2%;
  }

  display: flex;

  justify-content: space-evenly;
  align-items: center;
}

@mixin contentColumn($side) {
  $colSpan: 0;
  @if $side == "left" {
    $colSpan: 1 / 2;
    margin-right: 1%;
  } @else if $side == "right" {
    $colSpan: 2 / 3;
    margin-left: 4%;
  }

  grid: {
    column: $colSpan;
    row: 2;
  }
  margin: {
    top: 1%;
    bottom: 1.5%;
  }

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding: 25% 0;
}
