.console {
  min-width: 50vw;
  display: block;
  text-align: center;
  z-index: 3;
  position: relative;
}

.gameHeaderLinkFixed {
  background-image: url("../images/mainbkgd.png");
  background-size: contain;
  background-repeat: no-repeat;
  position: fixed;
  width: 964px;
  height: 643px;
  top: -10vh;
  left: 50%;
  transform: translate(-50%);
}

.panelHolder {
  position: absolute;
  top: 10.2%;
  left: 1.4%;
  width: 97.5%;
  height: 88.5%;
}
