@import "common";

.popupBox {
  @include popup-common;
  background-image: url("../images/popupwarning.png");

  height: 302px;
  width: 505px;
}

.text {
  position: absolute;
  z-index: 93;
  width: 69%;
  margin-left: 14%;
  color: rgb(166, 255, 189);
  padding: 5px;
}

.textWarning {
  color: #ff4846;
}

.textInfo {
  color: #f9fe70;
}

.title {
  composes: text;

  font-size: 20px;
  letter-spacing: 0.02em;
  text-align: left;

  line-height: 24px;

  height: 24px;
  margin-top: 13%;
}

.message {
  composes: text;
  text-align: left;

  font-size: 18px;
  letter-spacing: 0.01em;
  line-height: 20px;

  margin-top: 21%;

  overflow: hidden;
}

.buttonBase {
  @include reset-button;
  position: absolute;
  background-size: contain;
  background-repeat: no-repeat;

  height: 8%;
  width: 84%;

  top: 80.8%;
  left: 8%;
}

.acknowledge {
  composes: buttonBase;
  @include button-images("acknowledge");
}

.submit {
  composes: buttonBase;
  @include button-images("submit");
}
