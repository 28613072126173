@import "common";
@import "Services.module.scss";

.borrowCol {
  @include contentColumn("left");
}

.repayCol {
  @include contentColumn("right");
}

.buttonBorrow {
  @include footer-button;
  @include button-images("borrow");
}

.buttonRepay {
  @include footer-button;
  @include button-images("repay");
}
