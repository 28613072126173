* {
  font-family: liquidCrystal, monospace;
}

.mainbox {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  width: 803px;
}
.mainbox div {
  float: left;
}
.mainbox.home {
  background-image: url("../images/home-bkgd.jpg");
  height: 828px;
}
#feedglass {
  height: 744px;
  width: 453px;
}
#feedglass .feed-container {
  height: 525px;
  width: 345px;
  margin-left: 60px;
  margin-top: 147px;
  float: left;
  font-family: terminusmedium;
  overflow: hidden;
}
#feedglass .feed-container .feed-entry {
  float: left;
  margin-bottom: 15px;
  margin-top: 5px;
}
#feedglass .feed-container .feed-entry span {
  float: left;
  width: 100%;
}
#feedglass .feed-container .feed-entry .date {
  font-style: italic;
}
#feedglass .feed-container .feed-entry .title {
  font-weight: bold;
}
#feedglass .feed-container .feed-entry .content {
  font-size: 16px;
}
#feedglass .feed-container .feed-entry .content a {
  font-weight: bold;
}
#scoreglass {
  font-size: 18px;
  height: 689px;
  line-height: 120%;
  margin-left: 10px;
  width: 331px;
}
#scoreglass .score-container {
  height: 214px;
  margin-left: 54px;
  width: 228px;
}
#scoreglass .score-container.all-time {
  margin-top: 111px;
}
#scoreglass .score-container.weekly {
  margin-top: 75px;
}
#scoreglass .score-container .high-score {
  width: calc(100% + 2px);
  margin-left: -2px;
}
#scoreglass .score-container .high-score span {
  margin-left: 2px;
  overflow: hidden;
}
#scoreglass .score-container .high-score .name {
  float: left;
  overflow-x: hidden;
  padding-left: 1px;
  white-space: nowrap;
  width: 65px;
}
#scoreglass .score-container .high-score .score {
  float: left;
  margin-right: 5px;
  text-align: right;
  width: calc(100% - 115px);
  padding-left: 1px;
}
.cargo-container {
  height: 232px;
  margin-bottom: 45px;
  margin-left: 40px;
  margin-top: 140px;
  width: 538px;
}
.cargo-row {
  float: left;
  height: 30px;
  margin: 9px 0px 0px 0px;
  width: 100%;
}
.cargo-row * {
  float: left;
}
.cargo-row .sub {
  height: 30px;
}
.cargo-row .sub:last-child {
  float: left;
}
.cargo-row .sub .cargo-icon {
  height: 100%;
  margin-left: 20px;
  width: 35px;
}
.cargo-row .sub .cargo-name {
  width: 120px;
  opacity: 0;
}
.cargo-row .sub .cargo-price {
  text-align: left;
  width: 90px;
}
.cargo-row .sub .cargo-on-board {
  text-align: right;
  width: 80px;
}
.cargo-row .sub input.cargo-quantity {
  height: 95%;
  margin-left: 10px;
  margin-top: -5px;
  width: 90px;
}
.cargo-row .sub .max-button {
  background-size: inherit;
  height: 19px;
  margin-left: 0;
  margin-right: 0;
  margin-top: -2px;
  width: 23px;
}

.cargo-row .sub .cargo-text {
  font-size: 18px;
  line-height: 20px;
  padding-left: 10px;
}
.planet-link {
  height: 52px;
  position: absolute;
  width: 52px;
}
.planet-link.caliban {
  right: 139px;
  bottom: 181px;
}
.planet-link.setebos {
  left: 93px;
  top: 206px;
}
.planet-link.earth {
  left: 514px;
  top: 73px;
}
.planet-link.pertia {
  left: 211px;
  top: 65px;
}
.planet-link.taspra {
  left: 678px;
  top: 138px;
}
.planet-link.umbriel {
  left: 392px;
  top: 363px;
}
@font-face {
  font-family: "liquidCrystal";
  src: url("../fonts/liquidcrystal-webfont.eot");
  src: url("../fonts/liquidcrystal-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/liquidcrystal-webfont.woff") format("woff"),
    url("../fonts/liquidcrystal-webfont.ttf") format("truetype"),
    url("../fonts/liquidcrystal-webfont.svg#liquid_crystalregular")
      format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "liquid_crystallight";
  src: url("../fonts/liquidcrystal-light-webfont.woff2") format("woff2"),
    url("../fonts/liquidcrystal-light-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "terminusmedium";
  src: url("../fonts/terminus-webfont.eot");
  src: url("../fonts/terminus-webfont.eot?#iefix") format("embedded-opentype"),
    url("../fonts/terminus-webfont.woff") format("woff"),
    url("../fonts/terminus-webfont.ttf") format("truetype"),
    url("../fonts/terminus-webfont.svg#terminusmedium") format("svg");
  font-weight: normal;
  font-style: normal;
}
.mainbox.about {
  background-image: url("../images/aboutbkgd.jpg");
  height: 421px;
}
.new-game-button.about {
  bottom: 51px;
  left: 612px;
}
.donate-button.about {
  bottom: 91px;
  right: 38px;
}
.small-main-box {
  left: 60px;
  position: absolute;
  width: 520px;
}
.small-main-box.header {
  top: 105px;
}
.small-main-box.content {
  font-family: terminusmedium;
  line-height: 120%;
  top: 155px;
}
@font-face {
  font-family: "liquidCrystal";
  src: url("../fonts/liquidcrystal-webfont.eot");
  src: url("../fonts/liquidcrystal-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/liquidcrystal-webfont.woff") format("woff"),
    url("../fonts/liquidcrystal-webfont.ttf") format("truetype"),
    url("../fonts/liquidcrystal-webfont.svg#liquid_crystalregular")
      format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "liquid_crystallight";
  src: url("../fonts/liquidcrystal-light-webfont.woff2") format("woff2"),
    url("../fonts/liquidcrystal-light-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "terminusmedium";
  src: url("../fonts/terminus-webfont.eot");
  src: url("../fonts/terminus-webfont.eot?#iefix") format("embedded-opentype"),
    url("../fonts/terminus-webfont.woff") format("woff"),
    url("../fonts/terminus-webfont.ttf") format("truetype"),
    url("../fonts/terminus-webfont.svg#terminusmedium") format("svg");
  font-weight: normal;
  font-style: normal;
}
.mainbox.bug {
  background-image: url("../images/bugbkgd.jpg");
  height: 421px;
}
.bug-form {
  position: absolute;
  width: 366px;
  height: 211px;
  top: 146px;
  left: 390px;
}
.bug-form textarea {
  border-style: solid;
  border-width: 1px;
  border-color: #358075;
  background-color: rgba(4, 8, 43, 0.5);
  font-family: terminusmedium;
  font-size: 17px;
  resize: none;
  height: 175px;
  top: 0px;
  left: 0px;
  width: 100%;
}
.submit {
  height: 19px;
  width: 349px;
}
.submit.bug {
  background-image: url("../images/submit.png");
  margin-left: 8px;
}
.overlay {
  background-color: rgba(0, 0, 0, 0.6);
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
  z-index: 5;
}

.overlay .popup {
  background-image: url("../images/popupwarning.png");
  height: 302px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 125px;
  position: relative;
  width: 505px;
  outline: none;
}
.overlay .popup .text {
  float: left;
  height: 175px;
  margin-left: 70px;
  margin-top: 60px;
  width: 350px;
}
.overlay .popup .text * {
  float: left;
  width: 100%;
}
.overlay .popup .text .title {
  font-weight: 700;
}
.overlay .popup .text .title.warning {
  color: #ff4846;
}
.overlay .popup .text .title.info {
  color: #f9fe70;
}
.overlay .popup .text .full-text {
  margin-top: 15px;
}
.overlay .popup .score-entry input {
  float: right;
  margin-right: 115px;
  margin-top: -110px;
  width: 130px;
}
.overlay .popup .close-button {
  top: 245px;
}

body {
  background-color: #000;
  background-repeat: no-repeat;
  background-position: center top;
  color: #cfffeb;
  font-family: liquidCrystal;
  font-size: 18px;
}
div {
  background-repeat: no-repeat;
}
input[type="tel"],
input[type="text"] {
  background-color: rgba(207, 255, 235, 0);
  border-color: #cfffeb;
  border-style: solid;
  border-width: 1px;
  text-align: right;
  width: 80px;
}
a {
  color: #37de99;
  font-weight: bold;
}
a:hover {
  color: #40f5c2;
}
a:visited {
  color: #339966;
}
#header {
  background-image: url("../images/headerbkgd.jpg");
  height: 196px;
  width: 1024px;
}
#preload {
  display: none;
}
.game-portal {
  position: relative;
}
#gamecontent {
  position: relative;
}
.left {
  float: left;
}
.right {
  float: right;
}
.group-container {
  height: 504px;
  position: relative;
  width: 783px;
  margin-left: 11px;
}

.group-container.map-container {
  background-image: url("../images/mapbase.jpg");
}

.ship-container {
  height: 55px;
  padding-top: 22px;
}
.ship-container div {
  float: left;
}
.ship-container .ship-info-block {
  float: left;
  margin-left: 30px;
  width: 350px;
}
.ship-container .ship-info-block .ship-location {
  float: left;
  padding-left: 3px;
  width: 100px;
}
.ship-container .ship-info-block .positionCredits {
  float: left;
  padding-left: 12px;
  width: 250px;
}
.ship-container .ship-info-block .cargobays {
  float: left;
  margin-left: 89px;
}
.ship-container .ship-info-block .positionTurns {
  float: right;
}
.planet-info-container {
  height: 349px;
  position: absolute;
  right: 31px;
  top: 125px;
  width: 151px;
  z-index: 2;
}
.planet-info-container .planet-info {
  height: 252px;
  width: 130px;
}

.special-option-button {
  bottom: 78px;
  height: 32px;
  position: absolute;
  right: 29px;
  width: 153px;
  z-index: 3;
}
.special-option-button.bank {
  background-image: url("../images/bank.png");
}
.special-option-button.refueling-station {
  background-image: url("../images/refuel.png");
}
.view-container {
  height: 100%;
  position: relative;
  width: 100%;
}

.endgame {
  background-image: url("../images/endgamebkgd.png");
}
.endgame .final-score {
  bottom: 65px;
  left: 311px;
  position: absolute;
}

.special-option-container .special-option-header {
  font-size: 18px;
  position: absolute;
  width: 255px;
  height: 60px;
  top: 62px;
}
.special-option-container .special-option-header.right {
  left: 320px;
}
.special-option-container .special-option-header.right span {
  padding-left: 10px;
  text-align: left;
}
.special-option-container .special-option-header.left {
  left: 45px;
}
.special-option-container .special-option-header.left span {
  padding-right: 10px;
  text-align: right;
}
.special-option-container .special-option-header span {
  float: left;
  margin-top: 5px;
  width: 100%;
}
.special-option-container .special-option-body {
  height: 225px;
  position: absolute;
  top: 140px;
  width: 255px;
  left: 40px;
}
.special-option-container .special-option-body.right {
  left: 320px;
}
.special-option-container .special-option-body.left {
  left: 45px;
  text-align: right;
}
.special-option-container .special-option-body.left span {
  padding-right: 10px;
}
.special-option-container .special-option-body.special-option-body--full {
  width: 435px;
  padding-left: 10px;
}
.special-option-container .special-option-body * {
  float: left;
  margin-left: 15px;
  margin-top: 10px;
}
.special-option-container .special-option-body input {
  margin-left: 17px;
  width: 150px;
}
.special-option-container .special-option-body input.centered.small {
  width: 60px;
}
.special-option-container .special-option-body span {
  font-size: 18px;
  margin-left: 0px;
  width: 100%;
}
.special-option-container .special-option-body span.shipyard-cost {
  margin-top: 25px;
  padding-right: 100px;
  text-align: right;
}
.special-option-container .special-option-body .high-score {
  margin-top: 0px;
  width: 100%;
}
.special-option-container .special-option-body .high-score span {
  margin-top: 0px;
  text-align: left;
}
.special-option-container .special-option-body .high-score .name {
  width: 35%;
  overflow-x: hidden;
  white-space: nowrap;
}
.special-option-container .special-option-body .high-score .score {
  width: 45%;
}
.special-option-container .special-option-body .max-button {
  margin-top: 12px;
}
.special-option-container .special-option-body .max-button.texted {
  background-image: url("../images/textedmaxbutton.png");
  width: 59px;
  height: 19px;
}
.special-option-container .special-option-body .action-button {
  height: 27px;
  width: 230px;
}

.clickable-option {
  opacity: 0.8;
}
.clickable-option:hover {
  opacity: 1;
}
.clickable-option.reverse {
  opacity: 1;
}
.clickable-option.reverse:hover {
  opacity: 0.8;
}
.new-game-button {
  background-image: url("../images/new-game.png");
  bottom: 43px;
  height: 32px;
  left: 601px;
  position: absolute;
  width: 153px;
}
.donate-button {
  height: 32px;
  position: absolute;
  width: 153px;
}
.donate-button.endgame {
  bottom: 83px;
  right: 29px;
}
.blank-market-button {
  position: absolute;
  background-image: url("../images/blankbottombuttons.png");
  width: 463px;
  height: 27px;
  bottom: 61px;
  left: 79px;
}
.centered {
  margin-left: auto;
  margin-right: auto;
}
#boxfooter {
  color: #37de99;
  font-size: 11px;
  font-family: terminusmedium;
  height: 11px;
  width: 804px;
  margin-top: 5px;
  text-align: center;
}
#boxfooter .copyright {
  float: left;
  margin-left: 45px;
}
#boxfooter .about {
  float: right;
  margin-right: 45px;
}

* {
  font-family: liquidCrystal;
}
