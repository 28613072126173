@mixin button-images($buttonName) {
  // Set normal, hover, and active background images based on a button name
  background-image: url("../images/buttons/#{$buttonName}Normal.png");

  &:active {
    background-image: url("../images/buttons/#{$buttonName}Click.png");
  }

  &:hover {
    background-image: url("../images/buttons/#{$buttonName}Hover.png");
  }
}

@mixin reset-button {
  // Remove default useragent button styling
  background: none no-repeat;
  background-size: contain;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

@mixin standard-button {
  // Position and size a button for below planet info
  @include reset-button;
  z-index: 20;

  position: absolute;
  bottom: 8.5%;
  right: 4%;

  height: 6.3%;
  width: 19.4%;
}

@mixin container {
  position: absolute;
  top: 10.2%;
  left: 1.4%;
  width: 97.5%;
  height: 88.5%;
}

@mixin footer-button {
  @include reset-button;
  width: 45%;
  height: 58%;
}

@mixin popup-common {
  position: absolute;
  background-size: contain;
  z-index: 91;
  overflow: hidden;

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 90;
  align-content: center;
  font-size: 28px;
}

@mixin small-button($buttonName) {
  @include button-images($buttonName);
  width: 23px;
  height: 19px;
  cursor: pointer;
  background: {
    size: contain;
    repeat: no-repeat;
  }

  margin: 0 10px;
  padding: 0;
}

.buttonMax {
  @include reset-button;
  @include button-images("max");
  height: 19px;
  width: 59px;
}

.blankButton {
  @include footer-button;
  background: {
    image: url("../images/buttons/blankButton.png");
    size: contain;
  }
}

.buyButton {
  @include footer-button;
  @include button-images("serviceBuy");
}

input.selectedSide {
  font-weight: normal;
  outline: 1px solid rgb(207, 255, 235);
}

.selectedSide {
  font-weight: bold;
}

input {
  height: 16px;
  width: 80px;
  border: 1px solid rgb(207, 255, 235);
  background-color: transparent;

  text-align: center;

  font: {
    family: liquidCrystal, monospace;
    size: 18px;
  }

  line-height: 30px;
  color: rgb(207, 255, 235);
}
